@font-face {
    font-family: 'diavloBold';
    src: url('../fonts/diavlo_bold_ii_37-webfont.woff');
    src: url('../fonts/diavlo_bold_ii_37-webfont.woff') format('woff'), url('../fonts/diavlo_bold_ii_37-webfont.woff2') format('woff2'), url('../fonts/diavlo_bold_ii_37-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'diavloBook';
    src: url('../fonts/diavlo_book_ii_37-webfont.woff');
    src: url('../fonts/diavlo_book_ii_37-webfont.woff') format('woff'), url('../fonts/diavlo_book_ii_37-webfont.woff2') format('woff2'), url('../fonts/diavlo_book_ii_37-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'diavloLight';
    src: url('../fonts/diavlo_light_ii_37-webfont.woff');
    src: url('../fonts/diavlo_light_ii_37-webfont.woff') format('woff'), url('../fonts/diavlo_light_ii_37-webfont.woff2') format('woff2'), url('../fonts/diavlo_light_ii_37-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }