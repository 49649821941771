.nav-item {
  .nav {
    background: $white;
    border-radius: 0 20px 0 20px;
    width: 300px;
    font-weight: 300;
    .nav-link {
      color: $color-dark !important;
      &:hover,
      &.active {
        color: $color-primary !important;
      }
    }
  }
}

.navbar-toggler {
  margin-top: 15px;
}

.navbar {
  background: $color-primary;
  margin-top: none;
  top: 0;
  font-size: 16px !important;
  a {
    color: $white;
  }
}

.header-scrolled .navbar {
  background: $color-primary;
}

.navbar-stick-light
 {
  background: $color-primary;
}

@media screen and (min-width: 992px) {
  .navbar {
    // height: 130px;
  }

  #logo {
    width: 260px;
    height: 120px;
    position: relative;
    bottom: -20px;
  }

  .navbar-light .navbar-nav .nav-link {
    padding-right: 75px;
  }

  #nav-left {
    padding-left: 30px;
  }
}