.slick-slide {
    ul {
        list-style: none;
        padding: 0px 20px;
        margin: 0px;
        color: #666;

        li {
            margin-bottom: 8px;
            &:before {
                content: '-';
                position: absolute;
                left: 1em;
              }
        }
    }
}

.price-box {
    @extend .card;
    border: 4px solid
}

.pricing {
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);
    // margin-bottom: 0 !important;
    @extend .mb-7;

    // .tx-dce-pi1:first-child &{
    //     margin-top: -5em;
    // }
    // RESPO!!!
}

.card-title {
    //@extend .pt-4;
    color: $color-primary;
}
  