header {
    background: url(../img/head-bg.jpg);
    background-size: cover;
    @extend .h-50;
    h1, h2, h3, p {
        color: $black !important;
        text-transform: uppercase;
    }
    h1, h2, h3 {
        letter-spacing: 0.05em;
    }
}