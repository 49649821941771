table {
    td {
        &:last-child {
            text-align: right;
        }
    }
}

.termine {
    font-size: 1em;
    td {
        width: 25%;
    }
}
