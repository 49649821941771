footer {
    @extend .shadow-4;
    @extend .pt-6;
    font-size: .8em;
    h3 {
        margin-top: 1em;
        color: $color-primary;
        font-size: 1.25em;
    }
    p span {
        display: block;
        margin: 0 0 2px 0 !important;
        font-family: 'diavloBold';

    }
}
