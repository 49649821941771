@import "components/fonts";
@import "components/header";
@import "components/effects";
@import "components/cards";
@import "components/tables";
@import "components/footer";
@import "components/layout";
@import "components/typography";
@import "components/navigation";

// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

// .section,
// .container{
//   max-width: 1140px;
//   margin: 0 auto;
// }

.container .wichtige-termine,
.container .full-width {

  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  .slick-slider {
    position: relative;
    display: block;
    max-width: calc(100vw - 10px);
  }

}

.pricing-2 {

  .price {
    font-size: 3.5rem;
  }

  .plan-1.5remname {
    font-size: 1.5rem;
  }

}

.btn-default {
  @extend .btn-primary;
}