.lead {
    @extend .lead-2;
}

a {
    font-family: 'diavloBook';
}

ul {
    padding-left: 1.2em;

    li {
        &:before {
            // content: '-';
            position: absolute;
            left: 1em;
        }
    }
}

.ce-bodytext {
    ul {
        padding-left: 1.2em;
        li {
            list-style-type: none;
            &:before {
                content: '–';
                position: absolute !important;
                left: 1em !important;
            }
        }
    }
}

.features {
    ul {
        li {
            &:before {
                content: '';
            }
        }
    }
}

b, strong {
    font-family: 'diavloBook';
}